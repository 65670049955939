import { useEffect, useReducer } from 'react'
import styles from '../teams/styles.module.scss'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'
import { OrganizationFilter } from './OrganizationFilter'
import { getOrganizationsFilter } from '../../shared/services/organization.service'
import { IOrganization } from '../../shared/interfaces/organization.interface'
import loadingStyles from '../../shared/components/customLoadingSpinner/loadingSpinner.module.scss'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import { getMasterData } from '../../shared/services/masterData.service'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'
import { initialState, reducer } from './Organization.reducer'
import { OrganizationDetails } from './OrganizationDetails'

const Organizations = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetchOrganizations = (searchQuery?: string): void => {
    const lowercaseSearchQuery = searchQuery?.toLowerCase()
    dispatch({ type: 'SET_LOADING', payload: true })

    getOrganizationsFilter({
      page: state.pageNumber,
      search: lowercaseSearchQuery ?? '',
    })
      .then((res) => {
        if (res.length < 10) {
          dispatch({ type: 'SET_LOAD_MORE', payload: true })
        }

        if (
          state.selectedData !== state.organizations[0] &&
          state.selectedData?.id !== '' &&
          state.organizations[0]?.id &&
          state.counter > 0
        ) {
          dispatch({
            type: 'SET_ORGANIZATIONS',
            payload: res.filter((org: any) =>
              org.name
                .toLowerCase()
                .includes(state.selectedData?.name.toLowerCase())
            ),
          })
        } else {
          dispatch({ type: 'INCREMENT_COUNTER' })

          if (searchQuery && searchQuery.length > 0) {
            const filteredOrganizations = res.filter((org) =>
              org.name.toLowerCase().includes(lowercaseSearchQuery ?? '')
            )
            dispatch({
              type: 'SET_ORGANIZATIONS',
              payload: filteredOrganizations,
            })
          } else {
            const filteredOrganizations = res.filter(
              (org) =>
                !lowercaseSearchQuery ||
                !org.name.toLowerCase().includes(lowercaseSearchQuery)
            )

            dispatch({
              type: 'SET_SELECTED_DATA',
              payload:
                state.counter === 0
                  ? filteredOrganizations[0]
                  : state.selectedData,
            })

            dispatch({
              type: 'SET_ORGANIZATIONS',
              payload: [
                ...filteredOrganizations,
                ...state.organizations,
              ].filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.id === item.id)
              ),
            })
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: 'SET_ERROR',
          payload: { show: true, message: error.message },
        })
      })
      .finally(() => {
        dispatch({ type: 'SET_LOADING', payload: false })
      })
  }

  const handleLoadMore = () => {
    if (!state.loadMore) {
      dispatch({
        type: 'SET_PAGE_NUMBER',
        payload: state.pageNumber + 1,
      })
    }
  }

  const onDataSelection = (data: IOrganization) => {
    dispatch({ type: 'SET_SELECTED_DATA', payload: data })
  }

  const onDataSearch = (searchQuery: string) => {
    fetchOrganizations(searchQuery)
    dispatch({ type: 'RESET_PAGE' })
  }

  useEffect(() => {
    fetchOrganizations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pageNumber])

  useEffect(() => {
    function fetchMasterData() {
      dispatch({ type: 'SET_LOADING', payload: true })
      getMasterData()
        .then((data) => {
          dispatch({ type: 'SET_MASTER_DATA', payload: data })
        })
        .catch((error) => {
          dispatch({
            type: 'SET_ERROR',
            payload: { show: true, message: error.message },
          })
        })
        .finally(() => {
          dispatch({ type: 'SET_LOADING', payload: false })
        })
    }

    fetchMasterData()
  }, [])

  useEffect(() => {
    if (state.selectedData && state.masterData.length > 0) {
      const industry = state.masterData
        .filter((d) => d.category === 'organization_industry')
        .find((d) => d.id === state.selectedData?.industry)?.value
      if (industry) {
        dispatch({ type: 'SET_INDUSTRY', payload: industry })
      }

      const refereeRelation = state.masterData
        .filter((d) => d.category === 'organization_relationship')
        .find((d) => d.id === state.selectedData?.user_relationship)?.value
      if (refereeRelation) {
        dispatch({
          type: 'SET_REFEREE_RELATION_TYPE',
          payload: refereeRelation,
        })
      }

      const orgType = state.masterData
        .filter((d) => d.category === 'org_type')
        .find((d) => d.id === state.selectedData?.type)?.value
      if (orgType) {
        dispatch({ type: 'SET_ORGANIZATION_TYPE', payload: orgType })
      }

      if (state.selectedData?.how_did_you_hear_about_us) {
        const howDidYouHear = state.masterData
          .filter((d) => d.category === 'how_did_you_hear_about_us')
          .find(
            (d) => d.id === state.selectedData?.how_did_you_hear_about_us
          )?.value
        if (howDidYouHear) {
          dispatch({ type: 'SET_HOW_DID_YOU_HEAR', payload: howDidYouHear })
        }
      }
    }
  }, [state.selectedData, state.masterData])

  return (
    <div>
      <div className={`${state.loading && loadingStyles.app_while_loading}`}>
        <TopNavigation tabValue={'3'} />
        <div className={styles.content}>
          <div className={styles.leftSide}>
            <OrganizationFilter
              disabled={state.loading}
              organizations={state.organizations}
              onSelection={onDataSelection}
              onSearch={onDataSearch}
              loadMore={handleLoadMore}
              setLoadMore={(value) =>
                dispatch({ type: 'SET_LOAD_MORE', payload: value })
              }
              resetPage={() => dispatch({ type: 'RESET_PAGE' })}
              handleSearchValue={(data) =>
                dispatch({ type: 'SET_SELECTED_DATA', payload: data })
              }
            />
          </div>
          {state.loading && <LoadingSpinner />}
          {state.selectedData && !state.loading && (
            <OrganizationDetails
              refereeRelationType={state.refereeRelationType}
              industry={state.industry}
              organizationType={state.organizationType}
              howDidYouHear={state.howDidYouHear}
              name={state.selectedData.name}
              referringUsername={state.selectedData.referring_user_name}
              referringUserMobile={state.selectedData.referring_user_mobile}
              referringUserEmail={state.selectedData.referring_user_email}
              referringCompanyName={state.selectedData.referring_company_name}
              parentUserName={state.selectedData.parent_user_name}
              phone={state.selectedData.phone}
              email={state.selectedData.email}
              website={state.selectedData.website}
              address={state.selectedData.address}
              referralText={state.selectedData.referral_text}
              dateJoined={state.selectedData.created_at}
            />
          )}
        </div>
      </div>
      <ErrorBannerModal
        open={state.error.show}
        onClose={() => {
          dispatch({
            type: 'SET_ERROR',
            payload: { show: false, message: '' },
          })
        }}
        errorMessage={state.error.message}
      />
    </div>
  )
}

export default Organizations
