import Test from '../../../images/avatar.png'
import styles from './styles.module.scss'
import LocationIcon from '../../../icons/location.icon'
import { CandidateOverview } from '../../interfaces/candidate.interface'

interface Props {
  candidate: CandidateOverview
  selected?: boolean
  fromJob: boolean
  onClick?: () => void
}

const CandidateCard: React.FC<Props> = ({
  candidate,
  selected,
  onClick,
  fromJob,
}) => {
  return (
    <div
      className={` mt-2 mt-md-0 ${selected ? styles.selectedCard : styles.card}`}
      key={`candidate-card-main-div-${candidate.id}}`}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <img
        className={styles.image}
        src={candidate.profile_url ?? Test}
        alt={'Candidate'}
      />
      <div className={styles.cardInner}>
        <div>
          <h5 className={'gray-color-text'}>{candidate.name}</h5>
          <div className={'d-flex'}>
            <div>
              {' '}
              <LocationIcon />
            </div>

            <h6 className={'ms-2 gray-color-text'}>{candidate.location}</h6>
          </div>
        </div>
        {fromJob && (
          <div className={`ms-2 ${styles.rateMark}`}>
            <h3 className={'text-success'}>{candidate.match_percentage}%</h3>
          </div>
        )}
      </div>
    </div>
  )
}

export default CandidateCard
