import { useEffect, useMemo, useState } from 'react'
import styles from '../../shared/components/candidateInfo/styles.module.scss'
import BagIcon from '../../icons/bag.icon'
import LocationIcon from '../../icons/location.icon'
import CandidateCatchUp from '../../shared/components/candidateInfo/candidateCatchUp'
import Interview from '../../shared/components/candidateInfo/interview'
import Negotiation from '../../shared/components/candidateInfo/negotiation'
import PaperWork from '../../shared/components/candidateInfo/paperWork'
import ReferenceCheck from '../../shared/components/candidateInfo/referenceCheck'
import CustomButton from '../../shared/components/customButton/customButton'
import Test from '../../images/organization.png'
import CandidateProfile from '../../shared/components/candidateInfo/candidateInfo'
import { HiringPage, HiringStage } from '../../shared/enums/hiring.enum'
import {
  CandidateDetails,
  CandidateStatus,
} from '../../shared/interfaces/candidate.interface'
import {
  AddToSupplyListType,
  HiringProcess,
} from '../../shared/interfaces/hiring.interface'
import {
  addToSupplyList,
  convertHiringStageToNumber,
  getSupplyListProcess,
  updateHiringProcess,
} from '../../shared/services/hiring.service'
import LoadingService from '../../shared/services/loading.service'
import HistoryModal from './historyModal'
import CustomModal from '../../shared/components/customModal/customModal'
import NoteModal from './noteModal'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import DownArrowIcon from '../../icons/downArrow.icon'
import colors from '../../scss/variables.module.scss'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'
import SuccessBannerModal from '../../shared/components/errorBannerModal/successBannerModal'

interface Props {
  tab: string
  disabled: boolean
  candidate: CandidateDetails | null
  hiringData: HiringProcess | null
  changedStage?: boolean
  onUpdateCandidate?: (details: CandidateDetails) => void
  onUpdateHiringData?: (process: HiringProcess) => void
  onStageChanged?: (updated: boolean) => void
  changedProcessPage?: (page: HiringStage) => void
  setParentPage?: (page: HiringStage) => void
  disableTabsAndButtons: boolean
}

const CandidateInfo: React.FC<Props> = ({
  tab,
  candidate,
  hiringData,
  disabled,
  onUpdateCandidate,
  onUpdateHiringData,
  onStageChanged,
  setParentPage,
  disableTabsAndButtons,
}) => {
  const [page, setPage] = useState<string>(tab)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [noteModal, setNoteModal] = useState<boolean>(false)
  const [historyModal, setHistoryModal] = useState<boolean>(false)
  const [stepper, setStepper] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [isClosed, setIsClosed] = useState<boolean>(false)
  const [disableTabButton, SetDisableTabButton] = useState<boolean>(false)
  const [responseModal, setResponseModal] = useState<boolean>(false)
  const [errorModal, setErrorModal] = useState<boolean>(false)
  const [responseModalMessage, setResponseModalMessage] = useState<string>('')
  const [subTabName, setSubTabName] = useState<string>('Candidates')
  const subTabList = [
    { text: 'Profile', value: 'Candidates' },
    { text: 'Culture Catch-Up', value: 'Culture_Catch_Up' },
    { text: 'Interview', value: 'Interview' },
    { text: 'Reference Check', value: 'Reference_Check' },
    { text: 'Negotiation', value: 'Negotiation' },
    { text: 'Documentation', value: 'Paper_Work' },
  ]

  const [supplyStatus, setSupplyStatus] = useState<boolean>(false)
  const [, setErrorModel] = useState(false)
  const [, setErrorMessage] = useState<string>('')
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  useEffect(() => {
    if (hiringData?.id) {
      loadingService
        .await(getSupplyListProcess(hiringData.id))
        .then(setSupplyStatus)
        .catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
    }
    // eslint-disable-next-line
  }, [hiringData?.id])

  const handleAddToSupply = async () => {
    setIsLoading(true)
    if (!hiringData || !hiringData.id) return
    const data: AddToSupplyListType = {
      hiringProcessId: hiringData.id ?? '',
      stage: hiringData?.stage,
    }
    if (data) {
      const res = await addToSupplyList(data)
      if (res) {
        setIsLoading(false)
        setResponseModal(true)
        setResponseModalMessage(
          'This candidate has been marked as unsuccessful.'
        )
      } else {
        setIsLoading(false)
        setErrorModal(true)
        setResponseModalMessage(
          'Something went wrong when making the candidate unsuccessful.'
        )
      }
    } else {
      setIsLoading(false)
      setErrorModal(true)
      setResponseModalMessage(
        'Something went wrong when making the candidate unsuccessful.'
      )
    }
    if (hiringData?.id) {
      loadingService
        .await(getSupplyListProcess(hiringData.id))
        .then(setSupplyStatus)
        .catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
    }
  }

  useEffect(() => {
    if (hiringData?.id) {
      loadingService
        .await(getSupplyListProcess(hiringData?.id ?? ''))
        .then(setSupplyStatus)
        .catch(() => {
          setErrorModal(true)
          setResponseModalMessage(
            'Something went wrong when making the candidate unsuccessful.'
          )
        })
    }
    // eslint-disable-next-line
  }, [hiringData?.id])

  useEffect(() => {
    setIsDisabled(isLoading)
  }, [isLoading])

  useEffect(() => {
    setIsClosed(disabled)
  }, [disabled])

  useEffect(() => {
    setPage(tab)
  }, [tab])

  const handleMoveToNextStage = () => {
    let nextStage: HiringStage
    switch (hiringData?.stage) {
      case HiringStage.Pending:
        nextStage = HiringStage.Screening
        break
      case HiringStage.Screening:
        nextStage = HiringStage.CultureCatchUp
        break
      case HiringStage.CultureCatchUp:
        nextStage = HiringStage.Interview
        break
      case HiringStage.Interview:
        nextStage = HiringStage.ReferenceCheck
        break
      case HiringStage.ReferenceCheck:
        nextStage = HiringStage.Negotiation
        break
      case HiringStage.Negotiation:
        nextStage = HiringStage.PaperWork
        break
      case HiringStage.PaperWork:
        nextStage = HiringStage.Completed
        break
      default:
        return
    }
    if (
      nextStage === HiringStage.CultureCatchUp ||
      nextStage === HiringStage.Screening
    ) {
      loadingService
        .await(
          updateHiringProcess(
            {
              candidate_id: candidate?.id ?? '',
              job_id: hiringData?.job_id ?? '',
            },
            {
              stage: HiringStage.CultureCatchUp,
              hiringProcessId: hiringData.id,
            }
          ).then((success) => {
            if (!success) {
              setErrorModel(true)
              setErrorMessage('Update Error')
            }
          })
        )
        .then(() => {
          if (onUpdateHiringData) {
            onUpdateHiringData({
              ...hiringData,
              stage: nextStage,
            })
          }
          if (setParentPage) {
            setParentPage(nextStage)
          }
        })
    }
  }

  useEffect(() => {
    if (subTabName !== hiringData?.stage) {
    } else {
      SetDisableTabButton(false)
    }
  }, [hiringData?.stage, subTabName])

  const handleTabChange = (tabName: string) => {
    if (
      convertHiringStageToNumber(tabName) <=
      convertHiringStageToNumber(hiringData?.stage ?? '')
    ) {
      if (tabName !== hiringData?.stage) {
        SetDisableTabButton(true)
      } else {
        SetDisableTabButton(false)
      }
      setPage(tabName)
      setSubTabName(tabName)
    }
  }

  const isTabDisabled = (tabName: string) => {
    const currentStageIndex = convertHiringStageToNumber(
      hiringData?.stage ?? ''
    )
    const tabStageIndex = convertHiringStageToNumber(tabName)
    return tabStageIndex > currentStageIndex
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <div style={{ opacity: disabled ? 1 : 1 }}>
        {!candidate || !hiringData ? (
          <h5 className={'gray-color-text'}>No candidate selected</h5>
        ) : (
          <div>
            <div className={`justify-content-between ${styles.responsive}`}>
              <div className={'d-flex'}>
                <img
                  className={styles.image}
                  src={candidate.profile_url ?? Test}
                  alt={'Candidate'}
                />
                <div
                  className={'d-flex flex-column justify-content-between ms-3 '}
                >
                  <div className="d-flex justify-content-between">
                    <h5 className={'gray-color-text'}>{candidate.name}</h5>
                    {supplyStatus && (
                      <h6 className={styles.tagUnsuccessfulStyle}>
                        Unsuccessful Candidate
                      </h6>
                    )}
                    {candidate.status === CandidateStatus.Inactive && (
                      <h6 className={styles.tagInactiveStyle}>
                        {candidate.status.charAt(0).toUpperCase() +
                          candidate.status.slice(1)}
                      </h6>
                    )}
                  </div>
                  <div className={'d-flex align-items-center'}>
                    <div className={'d-flex me-4'}>
                      <LocationIcon />
                      <h6 className={'ms-3 gray-color-text'}>
                        {candidate.location}
                      </h6>
                    </div>
                  </div>
                  <div className={'d-flex'}>
                    <BagIcon />
                    <h6 className={'ms-3 gray-color-text text-normal'}>
                      Availability -{' '}
                      <span className={'text-bold'}>
                        {candidate.notice_period_days > 0
                          ? `${Math.ceil(candidate.notice_period_days / 7)} week${Math.ceil(candidate.notice_period_days / 7) > 1 ? 's' : ''}`
                          : 'Immediate'}
                      </span>
                    </h6>
                  </div>
                  <div className={'d-flex'}>
                    <div
                      className={`pointer ${styles.roundTag}`}
                      onClick={() => setNoteModal(true)}
                    >
                      <h6 className={'gray-color-text'}>Notes</h6>
                    </div>
                    <div
                      className={`ms-3 pointer ${styles.roundTag}`}
                      onClick={() => setHistoryModal(true)}
                    >
                      <h6 className={'gray-color-text'}>History</h6>
                    </div>
                  </div>
                  {candidate.hiredJob && (
                    <div className={'d-flex align-items-center'}>
                      <div className={'d-flex me-4'}>
                        <h6 className={'ms-3 gray-color-text'}>
                          <a href={`/dashboard?id=${candidate.hiredJob.id}`}>
                            {candidate.hiredJob.title}
                          </a>
                        </h6>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <>
                {(hiringData.stage === HiringStage.Screening ||
                  hiringData.stage === HiringStage.Pending) && (
                  <CustomButton
                    disabled={isDisabled || supplyStatus || isClosed}
                    text={'Move to Culture Catch-Up'}
                    className={`mt-3 mt-md-0 ${styles.widthBtn}`}
                    onClick={() => handleMoveToNextStage()}
                  />
                )}
              </>
            </div>

            <div
              className={`justify-content-between align-items-center mt-5 ${styles.displayResponsive} ${styles.widthMenu}`}
            >
              {subTabList.map((value, index) => (
                <div
                  key={index}
                  className={
                    isTabDisabled(value.value)
                      ? styles.disabled
                      : page === value.value
                        ? styles.tabSelected
                        : styles.roundTag
                  }
                  onClick={() => handleTabChange(value.value)}
                >
                  <h6
                    className={
                      page === value.value ? 'text-white' : 'gray-color-text'
                    }
                  >
                    {value.text}
                  </h6>
                </div>
              ))}
            </div>
            <div
              className={`mt-3 ${styles.mobileStepper}`}
              onClick={() => setStepper(!stepper)}
            >
              <div
                className={'d-flex justify-content-between align-items-center'}
              >
                <h6>{page}</h6>
                <DownArrowIcon color={colors.blackColor} />
              </div>
              {stepper && (
                <div className={styles.subMobileStepper}>
                  {subTabList.map((value, index) => (
                    <div
                      key={index}
                      className={styles.stepperInside}
                      onClick={() => handleTabChange(value.value)}
                    >
                      <h6>{value.text}</h6>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {page === HiringPage.Candidates && (
              <CandidateProfile
                isClosed={isClosed}
                candidate={candidate}
                hiringData={hiringData}
                disabled={isDisabled}
                onUpdateCandidate={onUpdateCandidate}
                onUpdateHiringData={onUpdateHiringData}
              />
            )}
            {page === HiringPage.CultureCatchUp && (
              <CandidateCatchUp
                isClosed={disabled || disableTabButton}
                candidate={candidate}
                tab={page}
                hiringData={hiringData}
                changePage={handleTabChange}
                onUpdateHiringData={onUpdateHiringData}
                onStageChanged={onStageChanged}
                setPage={(page) => {
                  setPage(page)
                  if (setParentPage) {
                    setParentPage(page)
                  }
                }}
                supplyStatus={supplyStatus || disabled}
                setSupplyStatus={setSupplyStatus}
              />
            )}
            {page === HiringPage.ReferenceCheck && (
              <ReferenceCheck
                disableTabsAndButtons={
                  disableTabsAndButtons || disableTabButton
                }
                hiringData={hiringData}
                jobId={hiringData.job_id}
                setPage={(page) => {
                  setPage(page)
                  if (setParentPage) {
                    setParentPage(page)
                  }
                }}
                isClosed={disabled || disableTabButton}
                handleAddToSupply={() => handleAddToSupply()}
                supplyStatus={supplyStatus || disabled}
                candidate={candidate}
                onStageChanged={onStageChanged}
                onUpdateHiringData={onUpdateHiringData}
              />
            )}
            {page === HiringPage.Interview && (
              <Interview
                setPage={(page) => {
                  setPage(page)
                  if (setParentPage) {
                    setParentPage(page)
                  }
                }}
                isClosed={disabled || disableTabButton}
                candidate={candidate}
                tab={page}
                hiringData={hiringData}
                onStageChanged={onStageChanged}
                supplyStatus={supplyStatus || disabled}
                setSupplyStatus={setSupplyStatus}
                onUpdateHiringData={onUpdateHiringData}
              />
            )}
            {page === HiringPage.Negotiation && (
              <Negotiation
                disableTabsAndButtons={
                  disableTabsAndButtons || disableTabButton
                }
                candidate={candidate}
                tab={page}
                hiringData={hiringData}
                changePage={handleTabChange}
                onUpdateHiringData={onUpdateHiringData}
                onStageChanged={onStageChanged}
                setPage={(page) => {
                  setPage(page)
                  if (setParentPage) {
                    setParentPage(page)
                  }
                }}
                handleAddToSupply={() => handleAddToSupply()}
                supplyStatus={supplyStatus || disabled}
              />
            )}
            {page === HiringPage.PaperWork && (
              <PaperWork
                disableTabsAndButtons={
                  disableTabsAndButtons || disableTabButton
                }
                setPage={(page) => {
                  setPage(page)
                  if (setParentPage) {
                    setParentPage(page)
                  }
                }}
                candidate={candidate}
                hiringData={hiringData}
                handleAddToSupply={() => handleAddToSupply()}
                supplyStatus={supplyStatus || disabled}
              />
            )}
          </div>
        )}
        {historyModal && (
          <CustomModal
            open={historyModal}
            onCloseModal={(val: any) => setHistoryModal(val)}
            title={'History'}
          >
            <HistoryModal hiringData={hiringData} />
          </CustomModal>
        )}
        {noteModal && (
          <CustomModal
            open={noteModal}
            onCloseModal={(val: any) => setNoteModal(val)}
            title={'Notes'}
          >
            <NoteModal
              onCloseModal={(val) => setNoteModal(val)}
              candidate={candidate}
              hiringData={hiringData}
              onUpdateHiringData={onUpdateHiringData}
            />
          </CustomModal>
        )}
        <SuccessBannerModal
          onClose={() => setResponseModal(false)}
          open={responseModal}
          Message={responseModalMessage}
        />
        <ErrorBannerModal
          onClose={() => setErrorModal(false)}
          open={errorModal}
          errorMessage={responseModalMessage}
        />
      </div>
    </>
  )
}

export default CandidateInfo
