import { type IOrganization } from '../../shared/interfaces/organization.interface'

interface State {
  organizations: IOrganization[]
  selectedData: IOrganization | null
  industry: string
  organizationType: string
  refereeRelationType: string
  howDidYouHear: string
  pageNumber: number
  loadMore: boolean
  loading: boolean
  error: {
    show: boolean
    message: string
  }
  masterData: any[]
  counter: number
}

type Action =
  | { type: 'SET_ORGANIZATIONS'; payload: IOrganization[] }
  | { type: 'SET_SELECTED_DATA'; payload: IOrganization | null }
  | { type: 'SET_INDUSTRY'; payload: string }
  | { type: 'SET_ORGANIZATION_TYPE'; payload: string }
  | { type: 'SET_REFEREE_RELATION_TYPE'; payload: string }
  | { type: 'SET_HOW_DID_YOU_HEAR'; payload: string }
  | { type: 'SET_PAGE_NUMBER'; payload: number }
  | { type: 'SET_LOAD_MORE'; payload: boolean }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: { show: boolean; message: string } }
  | { type: 'SET_MASTER_DATA'; payload: any[] }
  | { type: 'INCREMENT_COUNTER' }
  | { type: 'RESET_PAGE' }

export const initialState: State = {
  organizations: [],
  selectedData: null,
  industry: '',
  organizationType: '',
  refereeRelationType: '',
  howDidYouHear: '',
  pageNumber: 0,
  loadMore: false,
  loading: false,
  error: {
    show: false,
    message: '',
  },
  masterData: [],
  counter: 0,
}

// Reducer function
export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_ORGANIZATIONS':
      return { ...state, organizations: action.payload }
    case 'SET_SELECTED_DATA':
      return { ...state, selectedData: action.payload }
    case 'SET_INDUSTRY':
      return { ...state, industry: action.payload }
    case 'SET_ORGANIZATION_TYPE':
      return { ...state, organizationType: action.payload }
    case 'SET_REFEREE_RELATION_TYPE':
      return { ...state, refereeRelationType: action.payload }
    case 'SET_HOW_DID_YOU_HEAR':
      return { ...state, howDidYouHear: action.payload }
    case 'SET_PAGE_NUMBER':
      return { ...state, pageNumber: action.payload }
    case 'SET_LOAD_MORE':
      return { ...state, loadMore: action.payload }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    case 'SET_ERROR':
      return { ...state, error: action.payload }
    case 'SET_MASTER_DATA':
      return { ...state, masterData: action.payload }
    case 'INCREMENT_COUNTER':
      return { ...state, counter: state.counter + 1 }
    case 'RESET_PAGE':
      return { ...state, pageNumber: 0 }
    default:
      return state
  }
}
