import { useCallback, useEffect, useMemo, useState } from 'react'
import styles from '../teams/styles.module.scss'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'
import { OrganizationFilter } from '../organization/OrganizationFilter'

import { getOrganizationsFilter } from '../../shared/services/organization.service'
import { IOrganization } from '../../shared/interfaces/organization.interface'
import LoadingService from '../../shared/services/loading.service'
import loadingStyles from '../../shared/components/customLoadingSpinner/loadingSpinner.module.scss'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import ExpandableView from './expnadableView'
import {
  getQuestionsForOrg,
  GetQuestionsResponse,
} from '../../shared/services/needAnalysis.service'
import { RequestStatus } from '../../shared/types/http.model'
import { NeedsAnalysisTaskType } from './needsAnalysis.model'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'

export type Response = {
  question_id: string
  task: string
  text: string
  type: string
  response: string | null
  answer_id: string | null
}

/**
 * An array that defines the order of categories for the needs analysis tasks.
 * The order is based on the priority of the categories.
 */
const CategoryOrder = [
  NeedsAnalysisTaskType.General,
  NeedsAnalysisTaskType.Software,
  NeedsAnalysisTaskType.AR,
  NeedsAnalysisTaskType.AP,
  NeedsAnalysisTaskType.Banking,
  NeedsAnalysisTaskType.Payroll,
  NeedsAnalysisTaskType.BAS,
  NeedsAnalysisTaskType.EOM,
  NeedsAnalysisTaskType.EOFY,
]

const NeedAnalysis = () => {
  // State variables
  const [organizations, setOrganizations] = useState<IOrganization[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [responses, setResponses] = useState<Response[]>([])
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const [searchQueryValue, setSearchQueryValue] = useState<string>('')
  const [selectedData, setSelectedData] = useState<IOrganization | null>(null)
  const [counter, setCounter] = useState<number>(0)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  // Loading manager
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )

  const fetchOrganizations = useCallback(
    (searchQuery?: string): void => {
      const lowercaseSearchQuery = searchQuery?.toLowerCase()
      loadingService
        .await(
          getOrganizationsFilter({
            page: pageNumber,
            search:
              searchQueryValue !== ''
                ? searchQueryValue
                : (lowercaseSearchQuery ?? ''),
          })
        )
        .then((res) => {
          if (res.length < 10) {
            setLoadMore(true)
          }
          if (searchQuery && searchQuery.length > 0) {
            setOrganizations([...res])
          } else {
            setCounter(counter + 1)
            setSelectedData(counter === 0 ? res[0] : null)
            // Remove organizations with names matching the search query
            const filteredOrganizations = res.filter(
              (org) =>
                !lowercaseSearchQuery ||
                !org.name.toLowerCase().includes(lowercaseSearchQuery)
            )

            setOrganizations(() =>
              [...filteredOrganizations, ...organizations].filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.id === item.id)
              )
            )
          }
        })
        .catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingService, pageNumber]
  )

  function handleQuestionsResponse(res: GetQuestionsResponse): void {
    if (res.status === RequestStatus.Success && res.result) {
      setResponses(
        res.result.map((q) => ({
          question_id: q.id,
          answer_id: q.answer?.id ?? null,
          answer: q.answer ?? null,
          answers: q.answers ?? null,
          task: q.task ?? null,
          text: q.text ?? null,
          type: q.type ?? null,
          response: q.response ?? null,
        }))
      )
    } else {
      alert('Error loading questions. Please try again later.')
    }
  }

  /**
   * Set the selected organization data
   * Handles event from the child component
   * @param data The organization data to be selected
   * @returns void
   */
  const getData = async (orgId: string) => {
    try {
      setIsLoading(true)
      const obj = {
        id: orgId,
      }
      await loadingService
        .await(getQuestionsForOrg(obj))
        .then(handleQuestionsResponse)
        .catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
      setIsLoading(false)
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }
  const onDataSelection = async (data: IOrganization): Promise<void> => {
    await getData(data.id)
  }

  useEffect(() => {
    if (selectedData) {
      getData(selectedData.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData])

  /**
   * Search organizations based on the search query
   * Handles event from the child component
   * @param searchQuery The search query to filter the organizations
   * @returns void
   */
  const onDataSearch = (searchQuery: string): void => {
    fetchOrganizations(searchQuery)
  }

  /**
   * Handles the "Load More" button click event.
   * If loadMore is false, it increments the pageNumber state by 1.
   */
  const handleLoadMore = () => {
    if (!loadMore) {
      setPageNumber(pageNumber + 1)
    }
  }

  // Fetch organizations on component mount
  useEffect(() => {
    fetchOrganizations()
    return () => setOrganizations([])
  }, [fetchOrganizations, pageNumber])

  return (
    <div>
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        {isLoading && <LoadingSpinner />}
        <TopNavigation tabValue={'4'} />
        <div className={styles.content}>
          <div className={styles.leftSide}>
            <OrganizationFilter
              disabled={isLoading}
              organizations={organizations}
              onSelection={onDataSelection}
              onSearch={onDataSearch}
              loadMore={handleLoadMore}
              setLoadMore={setLoadMore}
              searchValue={() => setSearchQueryValue}
              resetPage={() => setPageNumber(0)}
            />
          </div>
          <div className={styles.rightWidth}>
            <h3 className="text-bolder mb-5 mt-3 mt-md-0">Needs Analysis</h3>
            {responses.length > 0 && (
              <>
                {CategoryOrder.map((category: NeedsAnalysisTaskType) => (
                  <ExpandableView
                    key={category}
                    data={responses.filter((d) => d.task === category)}
                    title={category}
                    description={''}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default NeedAnalysis
