import { useEffect, useMemo, useState } from 'react'
import styles from '../teams/styles.module.scss'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'
import Settings from './settings'
import CustomButton from '../../shared/components/customButton/customButton'
import RightLongArrowIcon from '../../icons/rightLongArrow.icon'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import EditIcon from '../../icons/edit.icon'
import {
  addHardSkillsAssessmentQuestions,
  getHardSkillsAssessmentQuestions,
  removeHardSkillsAssessmentQuestions,
} from '../../shared/services/assessment.service'
import { HardSkillsAssessmentQuestion } from '../../shared/interfaces/assessment.interface'
import { HardSkillsAssessmentQuestionType } from '../../shared/enums/assessment.enum'
import LoadingService from '../../shared/services/loading.service'
import { MasterDataCategory } from '../../shared/enums/masterData.enum'
import CustomDropdown, {
  DropdownDataList,
} from '../../shared/components/customDropdown/customDropdown'
import PlusIcon from '../../icons/plus.icon'
import CustomModal from '../../shared/components/customModal/customModal'
import AddNewQuestion from './addNewQuestion'
import { getMasterData } from '../../shared/services/masterData.service'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'

// NOTE TO DEVS.
// Categories for assessment categories are really messy at the moment.
// They are either a plain text string 'Accounts Payabale' OR a master data ID '123-123-123, etc.'
// It looks like the original questions from the client are the plain text string, but the new questions
// are the master data ID.
// Someone will need to go through and clean this up at some point. But I'm not going to do it right now.

const HardSkillAssessment = () => {
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<any>()
  const [questions, setQuestions] = useState<HardSkillsAssessmentQuestion[]>([])
  const [questionsToRemove, setQuestionsToRemove] = useState<string[]>([])
  const [categories, setCategories] = useState<DropdownDataList[]>([])
  const [selectedQuestion, setSelectedQuestion] =
    useState<HardSkillsAssessmentQuestion | null>(null)
  const [questionFilter, setQuestionFilter] = useState<
    (
      value: HardSkillsAssessmentQuestion,
      index: number,
      array: HardSkillsAssessmentQuestion[]
    ) => boolean
  >(() => () => true)

  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )

  function getSortedResult(array: any[]) {
    return array.sort((a: any, b: any) => {
      return a.text.localeCompare(b.text)
    })
  }

  // Get all questions and categories on page load
  useEffect(() => {
    loadingService
      .await(getHardSkillsAssessmentQuestions())
      .then(setQuestions)
      .catch((error: any) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    loadingService
      .await(getMasterData())
      .then((data: any) => {
        setCategories(
          data
            .filter((d: any) => d.category === MasterDataCategory.TaskExpertise)
            .map((d: any) => ({
              text: d.value,
              value: d.id,
            }))
        )
        const sorted = data
          .filter((d: any) => d.category === MasterDataCategory.TaskExpertise)
          .map((d: any) => ({
            text: d.value,
            value: d.id,
          }))
        setCategories(getSortedResult(sorted))
      })
      .catch((error: any) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [loadingService])

  /**
   * Handles the submission of the modal form
   * @param question The question to be added or edited
   * @returns void
   */
  const handleModalSubmit = (question: HardSkillsAssessmentQuestion): void => {
    if (selectedQuestion) {
      const index = questions.findIndex((q) => q.id === selectedQuestion.id)
      // When editing a question, we need to remove the old question and add the new one
      setQuestionsToRemove([...questionsToRemove, question.id])
      question.id = ''
      setQuestions([
        ...questions.slice(0, index),
        question,
        ...questions.slice(index + 1),
      ])
    } else {
      setQuestions([...questions, question])
    }
    setModalOpen(false)
  }

  /**
   * Handles the press of the edit icon
   * @param question The question to be edited
   * @returns void
   */
  const handleEditQuestion = (question: HardSkillsAssessmentQuestion): void => {
    setSelectedQuestion(question)
    setModalOpen(true)
  }

  /**
   * Handles the press of the delete icon
   * @param question The question to be deleted
   * @returns void
   */
  const handleDeleteQuestion = (
    question: HardSkillsAssessmentQuestion
  ): void => {
    const index = questions.findIndex((q) => q.id === question.id)
    setQuestions([...questions.slice(0, index), ...questions.slice(index + 1)])
    setQuestionsToRemove([...questionsToRemove, question.id])
  }

  /**
   * Handles the press of the add new question button
   * @returns void
   */
  const handleAddQuestion = (): void => {
    setSelectedQuestion(null)
    setModalOpen(true)
  }

  /**
   * Handles the press of the save questions button
   * Sends add and remove requests to the backend
   * @returns void
   */
  const handleSaveQuestions = (): void => {
    loadingService
      .await(
        addHardSkillsAssessmentQuestions(
          questions.filter((q) => q.id === '')
        ).then((res) => {
          if (!res) {
            setErrorModel(true)
            setErrorMessage('Error...')
          }
        })
      )
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    loadingService
      .await(
        removeHardSkillsAssessmentQuestions(questionsToRemove).then((res) => {
          if (res) {
            setErrorModel(true)
            setErrorMessage('Error...')
          }
        })
      )
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }

  /**
   * Updates the question filter based on the selected category
   * @param item The selected category dropdown item
   */
  const updateQuestionFilter = (item: DropdownDataList): void => {
    setSelectedCategory(item)
    if (item.value === '') {
      setQuestionFilter(() => () => true)
    } else {
      setQuestionFilter(() => (value: HardSkillsAssessmentQuestion) => {
        return (
          value.category === item.text ||
          value.category === item.value ||
          categories.find((c) => c.value === value.category)?.text === item.text
        )
      })
    }
  }

  return (
    <div>
      <TopNavigation tabValue={'settings'} />
      <div
        className={`d-md-flex justify-content-between mx-4 mx-md-5 my-1 my-md-5 `}
      >
        <Settings hardSkillAssessmentSelected={true} />
        <div
          className={`mx-md-5 mx-1 col-sm-12 col-lg-8`}
          style={{ opacity: isLoading ? 0.5 : 1 }}
        >
          <div>
            <div>
              <div
                className={`${styles.contentQuestion} ${styles.borderBottomNone}`}
              >
                <div className={`w-100 ${styles.widthLeft}`}>
                  <div
                    className={
                      'd-md-flex w-100 justify-content-between align-items-center'
                    }
                  >
                    <h2 className={`text-bolder text-almostBlack`}>
                      Technical Ability Assessment Questions
                    </h2>
                    <CustomButton
                      text={'Add New Question'}
                      icon={<PlusIcon />}
                      iconSide={'left'}
                      className={`mt-2 mt-md-0 ${styles.addQuestionBtn}`}
                      onClick={handleAddQuestion}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.searchBar}>
                <CustomDropdown
                  dataList={// Show the unique question categories in the dropdown
                  // Note that some categories are plain text and some are master data IDs
                  questions
                    ?.sort((a: any, b: any) => {
                      return a?.category?.localeCompare(b.category)
                    })
                    .map((q) => ({
                      value:
                        categories.find((c) => c.value === q.category)?.id ??
                        q.category,
                      text:
                        categories.find((c) => c.value === q.category)?.text ??
                        q.category ??
                        'Written Response',
                    }))
                    .filter(
                      (v, i, a) =>
                        a.indexOf(
                          a.find((c) => c.text === v.text) ?? {
                            text: '',
                            value: '',
                          }
                        ) === i
                    )}
                  placeHolder={'Skill Category'}
                  getSelectedItem={updateQuestionFilter}
                  selectedValue={selectedCategory}
                />
              </div>
            </div>
            <div className="ps-md-4">
              <div>
                {questions?.filter(questionFilter).map((question, index) => (
                  <div>
                    <div
                      className={`${styles.contentQuestion} ${styles.borderBottomNone}`}
                    >
                      <div
                        className={
                          'd-md-flex justify-content-between align-items-center w-100'
                        }
                      >
                        <div
                          className={`d-flex align-items-center mt-4 ${styles.questionWidth}`}
                        >
                          <h6 className={'text-normal ms-4'}>
                            <span className={'text-bolder me-4'}>
                              {index + 1}
                            </span>
                            {question.text}
                          </h6>
                        </div>
                        <div
                          className={` d-flex justify-content-around mt-2 mt-md-0 ${styles.categoryWidth}`}
                        >
                          <div className={styles.category}>
                            <h6 className={'text-info-color'}>
                              {question.type ===
                              HardSkillsAssessmentQuestionType.MultipleChoice
                                ? (categories.find(
                                    (c) => c.value === question.category
                                  )?.text ?? question.category)
                                : 'Written Response'}
                            </h6>
                          </div>
                          <div
                            className={'pointer ms-2'}
                            onClick={() => handleDeleteQuestion(question)}
                          >
                            <RecycleBinIcon />
                          </div>
                          <div
                            className={'pointer ms-2'}
                            onClick={() => handleEditQuestion(question)}
                          >
                            <EditIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ps-4">
                      {question?.answers.map((answer) => (
                        <div>
                          <div
                            className={`mb-2 ${styles.payableTag} ${
                              answer.correct && styles.selected
                            }`}
                          >
                            <div
                              className={
                                'd-flex justify-content-between align-items-center'
                              }
                            >
                              <h6 className={'text-normal ms-4'}>
                                {answer.text}
                              </h6>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={
                  'd-flex justify-content-end align-items-center mt-5 mb-5'
                }
              >
                <div className={'d-flex align-items-center'}>
                  <CustomButton
                    loading={isLoading}
                    type={'submit'}
                    text={'Publish Changes'}
                    icon={<RightLongArrowIcon size={14} />}
                    iconSide={'right'}
                    onClick={handleSaveQuestions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CustomModal
          open={isModalOpen}
          onCloseModal={() => setModalOpen(false)}
          title={
            selectedQuestion === null ? 'Add New Question' : 'Update Question'
          }
        >
          <AddNewQuestion
            question={selectedQuestion}
            onSubmit={handleModalSubmit}
            closeModal={() => setModalOpen(false)}
            categories={// Show the unique question categories in the dropdown
            // Note that some categories are plain text and some are master data IDs
            questions
              ?.map((q) => ({
                value:
                  categories.find((c) => c.value === q.category)?.id ??
                  q.category,
                text:
                  categories.find((c) => c.value === q.category)?.text ??
                  q.category ??
                  'Written Response',
              }))
              .filter(
                (v, i, a) =>
                  a.indexOf(
                    a.find((c) => c.text === v.text) ?? { text: '', value: '' }
                  ) === i
              )}
          />
        </CustomModal>
      )}
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default HardSkillAssessment
