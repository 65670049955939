import { useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'

import CustomDropdown from '../../shared/components/customDropdown/customDropdown'
import JobCard from '../../shared/components/jobCard/jobCard'
import SearchDropdown from '../../shared/components/customDropdown/searchDropdown'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import loadingStyles from '../../shared/components/customLoadingSpinner/loadingSpinner.module.scss'
import { JobDetails } from '../../shared/interfaces/job.interface'
import {
  getJobLocationDetails,
  getJobs,
} from '../../shared/services/job.service'
import ArrowDownIcon from '../../icons/downArrow.icon'
import color from '../../scss/variables.module.scss'
import FilterIcon from '../../icons/filter.icon'
import SortByIcon from '../../icons/sortBy.icon'
import FilterUpIcon from '../../icons/filterUp.icon'
import { getMasterData } from '../../shared/services/masterData.service'
import _ from 'lodash'
import { JobFilters } from '../../shared/components/filterModal/filterModal'
import { JobStatus } from '../../shared/enums/job.enum'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'

const statusList = [
  { value: '0', text: 'All' },
  { value: '1', text: 'Live' },
  { value: '2', text: 'Unpublished' },
]

const AllJobs = () => {
  const [activeStatusIndex, setActiveStatusIndex] = useState('0')
  const [activeSortIndex, setActiveSortIndex] = useState('0')
  const [isLoading, setIsLoading] = useState(false)
  const [jobs, setJobs] = useState<JobDetails[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchFilterJobs, setSearchFilterJobs] = useState<JobDetails[]>([])
  const [flipIcon, setFlipIcon] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [jobLocation, setJobLocation] = useState<any[]>([])
  const [preSearchData, setPreSearchData] = useState<any>({})
  const [preSearchPage, setPreSearchPage] = useState<number>(0)
  const [isDisableLoadMore, setIsDisableLoadMore] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterValue, setFilterValue] = useState<JobFilters>({})
  const [masterData, setMasterData] = useState<any[]>([])
  const [jobCategory, setJobCategory] = useState<string[]>([])

  // Add state variables for selected category and location filters
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const fetchItems = async () => {
    try {
      setIsLoading(true)
      //check object is equal and check page number is equal
      if (
        _.isEqual(preSearchData, {
          ...(searchValue ? { search: searchValue } : {}),
          ...filterValue,
          ...{ jobCategory: selectedCategory },
          ...{ location: selectedLocation },
          ...(activeStatusIndex === '1'
            ? { status: JobStatus.Open }
            : activeStatusIndex === '2'
              ? {}
              : {}),
          ...(activeStatusIndex === '0'
            ? { allJobStatus: '0' }
            : activeStatusIndex === '2'
              ? { allJobStatus: '2' }
              : {}),
        })
      ) {
        if (page !== preSearchPage) {
          setIsDisableLoadMore(false)
          const response = await getJobs({
            ...(searchValue ? { search: searchValue } : {}),
            ...filterValue,
            ...{ jobCategory: selectedCategory },
            ...{ location: selectedLocation },
            ...(activeStatusIndex === '1'
              ? { status: JobStatus.Open }
              : activeStatusIndex === '2'
                ? {}
                : {}),
            ...(activeStatusIndex === '0'
              ? { allJobStatus: '0' }
              : activeStatusIndex === '2'
                ? { allJobStatus: '2' }
                : {}),
            page: `${page}`,
          })
          const data = response
          if (data.length < 10) {
            setIsDisableLoadMore(true)
          }
          setJobs((prevItems) =>
            [...prevItems, ...data].filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.id.toLowerCase() === item.id.toLowerCase()
                )
            )
          )
          setSearchFilterJobs((prevItems) =>
            [...prevItems, ...data].filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.id.toLowerCase() === item.id.toLowerCase()
                )
            )
          )
        }
      } else {
        setIsDisableLoadMore(false)
        setPage(1)
        setSearchFilterJobs([])
        const response = await getJobs({
          ...(searchValue ? { search: searchValue } : {}),
          ...filterValue,
          ...{ jobCategory: selectedCategory },
          ...{ location: selectedLocation },
          ...(activeStatusIndex === '1'
            ? { status: JobStatus.Open }
            : activeStatusIndex === '2'
              ? {}
              : {}),
          ...(activeStatusIndex === '0'
            ? { allJobStatus: '0' }
            : activeStatusIndex === '2'
              ? { allJobStatus: '2' }
              : {}),
          page: `${Object.keys(filterValue).length !== 0 ? 1 : page}`,
        })
        const data = response
        if (data.length < 10) {
          setIsDisableLoadMore(true)
        }
        setSearchFilterJobs(data)
      }
      setPreSearchData({
        ...(searchValue ? { search: searchValue } : {}),
        ...filterValue,
        ...{ jobCategory: selectedCategory },
        ...{ location: selectedLocation },
        ...(activeStatusIndex === '1'
          ? { status: JobStatus.Open }
          : activeStatusIndex === '2'
            ? {}
            : {}),
        ...(activeStatusIndex === '0'
          ? { allJobStatus: '0' }
          : activeStatusIndex === '2'
            ? { allJobStatus: '2' }
            : {}),
      })
      setPreSearchPage(page)
      setIsLoading(false)
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }

  // Add event handlers for sorting by 'Job Title' and 'Published Date'
  const handleSortByTitle = () => {
    setActiveSortIndex('1') // Set to '1' for 'Job Title'
  }

  const handleSortByDate = () => {
    setActiveSortIndex('0') // Set to '0' for 'Published Date'
  }

  const handleLoadMore = () => {
    if (!isDisableLoadMore) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  // Event handler for status filter
  const handleStatusFilter = async (statusValue: any) => {
    setActiveStatusIndex(statusValue)
  }

  const handleCategoryFilter = async (categoryValue: any) => {
    setSelectedCategory(categoryValue)
  }

  const handleLocationFilter = (locationValue: any) => {
    setSelectedLocation(locationValue)
  }

  const masterDataList = useCallback(async () => {
    try {
      const res = await getMasterData()
      if (res) {
        setMasterData(res)
      } else {
        setMasterData([])
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }, [])

  useEffect(() => {
    masterDataList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (masterData.length > 0) {
      const category = masterData.filter(
        (item: any) => item.category === 'finance_jobs'
      )
      setJobCategory(category)
    } else {
      setJobCategory([])
    }
  }, [masterData])

  const fetchLocations = async () => {
    try {
      setIsLoading(true)
      const response = await getJobLocationDetails()
      setJobLocation(response)
      setIsLoading(false)
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchLocations()
  }, [])

  useEffect(() => {
    fetchItems().then((r) => r)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchValue, selectedCategory, selectedLocation, activeStatusIndex])

  return (
    <>
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        {isLoading && <LoadingSpinner />}
        <div className={'w-100'}>
          <TopNavigation tabValue={'1'} />
          <div className={styles.content}>
            <div className={styles.leftSide}>
              <div className={`${styles.statusCard}`}>
                <div className={'d-flex mb-3'}>
                  <div className={styles.dashboardSearch}>
                    <SearchDropdown
                      dataList={[]}
                      placeHolder={'Role'}
                      handleSearch={setSearchValue}
                    />
                  </div>
                </div>
                <div
                  className={
                    'd-flex justify-content-between align-items-center'
                  }
                >
                  <h6 className={'gray-color-text '}>Status</h6>
                </div>
                <div className={`d-flex w-50 justify-content-between mt-4 `}>
                  {statusList.map((val, index) => (
                    <button
                      key={`statusList-sub-one-${index}`}
                      className={
                        activeStatusIndex === `${index}`
                          ? `me-3 ${styles.activeBtn}`
                          : `me-3 ${styles.btn}`
                      }
                      onClick={() => handleStatusFilter(val.value)}
                    >
                      {val.text}
                    </button>
                  ))}
                </div>
                <div className={`mt-4 mb-4 pt-4 pb-4 ${styles.filter}`}>
                  <div
                    className={
                      'd-flex justify-content-between align-items-center'
                    }
                  >
                    <h6 className={'gray-color-text'}>Filter</h6>
                    <div>
                      <FilterIcon />
                    </div>
                  </div>
                  <div className={` mt-4 ${styles.dashboardSearch}`}>
                    <CustomDropdown
                      clear
                      placeHolder={'Category'}
                      name={'JobCategory'}
                      dataList={jobCategory.map((value: any) => ({
                        text: value.value,
                        value: value.id,
                      }))}
                      getSelectedItem={(i: any) =>
                        handleCategoryFilter(i.value)
                      }
                      selectedValue={
                        jobCategory
                          .filter((fd: any) => fd.id === selectedCategory)
                          .map((md: any) => ({
                            text: md.value,
                            value: md.id,
                          }))[0]
                      }
                    />
                    <div className={`mt-3 ${styles.dropdownWidth}`}>
                      <CustomDropdown
                        clear
                        placeHolder={'Location '}
                        name={'JobCategory'}
                        dataList={jobLocation.map((item) => ({
                          text: item.text ?? '',
                          value: item.value ?? '',
                        }))}
                        getSelectedItem={(item: any) =>
                          handleLocationFilter(item.value)
                        }
                        selectedValue={{
                          text: selectedLocation,
                          value: selectedLocation,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    'd-flex justify-content-between align-items-center'
                  }
                >
                  <h6 className={'gray-color-text'}>Sort by</h6>

                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => setFlipIcon(!flipIcon)}
                  >
                    {flipIcon ? <SortByIcon /> : <FilterUpIcon />}
                  </div>
                </div>
                <div className={'d-flex w-75 mt-2'}>
                  <button
                    key={`sortBy-sub-one-0`}
                    className={`me-3 ${
                      activeSortIndex === '0' ? styles.activeBtn : styles.btn
                    }`}
                    onClick={handleSortByDate} // Sort by Published Date
                  >
                    Published Date
                  </button>
                  <button
                    key={`sortBy-sub-one-1`}
                    className={`me-3 ${
                      activeSortIndex === '1' ? styles.activeBtn : styles.btn
                    }`}
                    onClick={handleSortByTitle} // Sort by Job Title
                  >
                    Job Title
                  </button>
                </div>
              </div>
            </div>
            <div className={`mt-5 ${styles.rightSide}`}>
              {searchFilterJobs.length > 0
                ? searchFilterJobs
                    .sort((a, b) => {
                      if (activeSortIndex === '1') {
                        // Sort by Job Title
                        return flipIcon
                          ? a.title?.localeCompare(b.title) || 0
                          : b.title?.localeCompare(a.title) || 0
                      } else {
                        // Sort by Published Date
                        const dateA = a.created_at
                          ? new Date(a.created_at)
                          : null
                        const dateB = b.created_at
                          ? new Date(b.created_at)
                          : null

                        if (dateA && dateB) {
                          return flipIcon
                            ? dateA.getTime() - dateB.getTime()
                            : dateB.getTime() - dateA.getTime()
                        }

                        // Handle the case when either dateA or dateB is null (undefined)
                        if (dateA === null) return 1
                        if (dateB === null) return -1

                        return 0
                      }
                    })
                    .map((job, index) => (
                      <JobCard
                        details={job}
                        disabled={false}
                        key={`jcf${index}`}
                      />
                    ))
                : jobs
                    .sort((a, b) => {
                      if (activeSortIndex === '1') {
                        // Sort by Job Title
                        return flipIcon
                          ? a.title?.localeCompare(b.title) || 0
                          : b.title?.localeCompare(a.title) || 0
                      } else {
                        // Sort by Published Date
                        const dateA = a.created_at
                          ? new Date(a.created_at)
                          : null
                        const dateB = b.created_at
                          ? new Date(b.created_at)
                          : null

                        if (dateA && dateB) {
                          return flipIcon
                            ? dateA.getTime() - dateB.getTime()
                            : dateB.getTime() - dateA.getTime()
                        }

                        // Handle the case when either dateA or dateB is null (undefined)
                        if (dateA === null) return 1
                        if (dateB === null) return -1

                        return 0
                      }
                    })
                    .map((job: JobDetails, index: number) => (
                      <div className="mt-3" key={`innerJob-card-${index}`}>
                        <JobCard
                          details={job}
                          disabled={false}
                          key={`jf${index}`}
                        />
                      </div>
                    ))}
              {isLoading && <LoadingSpinner />}
              <span
                className="d-flex align-items-center mt-3 mb-5"
                onClick={() => {
                  handleLoadMore()
                }}
                style={{ cursor: 'pointer' }}
              >
                <h6 className="me-4 mt-2 pointer text-tertiary-color">
                  Load More
                </h6>
                <ArrowDownIcon color={color.teritaryColor500} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default AllJobs
