import styles from '../teams/styles.module.scss'

interface Props {
  name: string
  referringUsername: string
  referringUserMobile: string
  referringUserEmail: string
  referringCompanyName: string
  refereeRelationType: string
  parentUserName: string
  phone: string
  email: string
  website: string
  address: string
  industry: string
  organizationType: string
  howDidYouHear: string
  referralText: string
  dateJoined: string
}

export const OrganizationDetails: React.FC<Props> = ({
  name,
  referringUsername,
  referringUserMobile,
  referringUserEmail,
  referringCompanyName,
  refereeRelationType,
  parentUserName,
  phone,
  email,
  website,
  address,
  industry,
  organizationType,
  howDidYouHear,
  referralText,
  dateJoined,
}) => {
  return (
    <div className={styles.rightWidth}>
      <div className={styles.responsive}>
        <div className={styles.contentQuestion}>
          <h4 className={`text-bolder text-almostBlack ${styles.widthLeft}`}>
            {name}
          </h4>
        </div>
      </div>
      {referringUsername && (
        <div className={styles.contentQuestion}>
          <h6 className={`gray-color-text ${styles.widthLeft}`}>
            Referral Name
          </h6>
          <div className={`${styles.answerLength}`}>
            <h6 className={'text-gray-600'}>{referringUsername}</h6>
          </div>
        </div>
      )}
      {referringUserMobile && (
        <div className={styles.contentQuestion}>
          <h6 className={`gray-color-text ${styles.widthLeft}`}>
            Referral Mobile
          </h6>
          <div className={`${styles.answerLength}`}>
            <h6 className={'text-gray-600'}>{referringUserMobile}</h6>
          </div>
        </div>
      )}
      {referringUserEmail && (
        <div className={styles.contentQuestion}>
          <h6 className={`gray-color-text ${styles.widthLeft}`}>
            Referral Email
          </h6>
          <div className={`${styles.answerLength}`}>
            <h6 className={'text-gray-600'}>{referringUserEmail}</h6>
          </div>
        </div>
      )}
      {/*Referral Company Name*/}
      {referringCompanyName && (
        <div className={styles.contentQuestion}>
          <h6 className={`gray-color-text ${styles.widthLeft}`}>
            Referral Company Name
          </h6>
          <div className={`${styles.answerLength}`}>
            <h6 className={'text-gray-600'}>{referringCompanyName}</h6>
          </div>
        </div>
      )}
      {refereeRelationType && (
        <div className={styles.contentQuestion}>
          <h6 className={`gray-color-text ${styles.widthLeft}`}>
            Referral Relationship
          </h6>
          <div className={`${styles.answerLength}`}>
            <h6 className={'text-gray-600'}>{refereeRelationType}</h6>
          </div>
        </div>
      )}
      {parentUserName && (
        <div className={styles.contentQuestion}>
          <h6 className={`gray-color-text ${styles.widthLeft}`}>
            Main Contact Name
          </h6>
          <div className={`${styles.answerLength}`}>
            <h6 className={'text-gray-600'}>{parentUserName}</h6>
          </div>
        </div>
      )}
      {phone && (
        <div className={styles.contentQuestion}>
          <h6 className={`gray-color-text ${styles.widthLeft}`}>
            Main Contact Mobile
          </h6>
          <div className={`${styles.answerLength}`}>
            <h6 className={'text-gray-600'}>{phone}</h6>
          </div>
        </div>
      )}
      {email && (
        <div className={styles.contentQuestion}>
          <h6 className={`gray-color-text ${styles.widthLeft}`}>
            Main Contact Email
          </h6>
          <div className={`${styles.answerLength}`}>
            <h6 className={'text-gray-600'}> {email}</h6>
          </div>
        </div>
      )}

      <div className={styles.contentQuestion}>
        <h6 className={`gray-color-text ${styles.widthLeft}`}>Web Address</h6>
        <div className={`${styles.answerLength} `}>
          <h6 className={'text-gray-600'}>{website}</h6>
        </div>
      </div>

      <div className={styles.contentQuestion}>
        <h6 className={`gray-color-text ${styles.widthLeft}`}>
          Organisation Address
        </h6>
        <div className={`${styles.answerLength}`}>
          <h6 className={'text-gray-600'}> {address}</h6>
        </div>
      </div>

      <div className={styles.contentQuestion}>
        <h6 className={`gray-color-text ${styles.widthLeft}`}>
          Industry ANZSIC code
        </h6>
        <div className={`${styles.answerLength}`}>
          <h6 className={'text-gray-600'}>{industry}</h6>
        </div>
      </div>

      <div className={styles.contentQuestion}>
        <h6 className={`gray-color-text ${styles.widthLeft}`}>
          Organisation Type
        </h6>
        <div className={`${styles.answerLength}`}>
          <h6 className={'text-gray-600'}>{organizationType}</h6>
        </div>
      </div>
      {howDidYouHear && (
        <div className={styles.contentQuestion}>
          <h6 className={`gray-color-text ${styles.widthLeft}`}>
            How did you hear about us{' '}
          </h6>
          <div className={`${styles.answerLength}`}>
            <h6 className={'text-gray-600'}> {howDidYouHear}</h6>
          </div>
        </div>
      )}
      {referralText && (
        <div className={styles.contentQuestion}>
          <h6 className={`gray-color-text ${styles.widthLeft}`}>
            Referral text
          </h6>
          <div className={`${styles.answerLength}`}>
            <h6 className={'text-gray-600'}> {referralText}</h6>
          </div>
        </div>
      )}
      {dateJoined && (
        <div className={styles.contentQuestion}>
          <h6 className={`gray-color-text ${styles.widthLeft}`}>Date joined</h6>
          <div className={`${styles.answerLength}`}>
            <h6 className={'text-gray-600'}>
              {' '}
              {new Date(dateJoined).toDateString()}
            </h6>
          </div>
        </div>
      )}
    </div>
  )
}
