import React, { useEffect, useMemo, useState } from 'react'
import styles from '../teams/styles.module.scss'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import CustomInputField from '../../shared/components/customInputField/customInputField'
import Settings from './settings'
import CustomButton from '../../shared/components/customButton/customButton'
import RightLongArrowIcon from '../../icons/rightLongArrow.icon'
import {
  getMasterData,
  updateMasterData,
} from '../../shared/services/masterData.service'
import { MasterDataEntry } from '../../shared/interfaces/masterData.interface'
import LoadingService from '../../shared/services/loading.service'
import * as Yup from 'yup'
import { RequiredStringSchema } from 'yup/lib/string'
import { AnyObject } from 'yup/lib/types'
import { getUserRole } from '../../shared/services/auth.service'
import { UserRole } from '../../shared/enums/auth.enum'
import { useNavigate } from 'react-router-dom'
import DollarIcon from '../../icons/dollar.icon'
import PercentageIcon from '../../icons/percentage.icon'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'

// The props for the form
interface CostCalculationProps {
  [key: string]: string

  payroll_multiplier: string
  banking_multiplier: string
  bank_account_fixed_cost: string
  accounts_payable_multiplier: string
  accounts_receivable_multiplier: string
  eom_multiplier: string
  cost_centre_fixed_cost: string
  currency_fixed_cost: string
  bas_multiplier: string
  ias_multiplier: string
  bas_lodgement_fixed_cost: string
  growth_rate: string
  super_rate: string
  payroll_tax_rate: string
  workers_compensation_rate: string
  annual_leave_rate: string
  personal_leave_rate: string
  public_holiday_rate: string
  bookkeeper_rate: string
  senior_bookkeeper_rate: string
  payroll_officer_rate: string
}

// Details for each field
const fieldDetails = [
  {
    property: 'payroll_multiplier',
    label: 'Payroll Multiplier',
    placeholder: 'Payroll Multiplier *',
    description: "Number of minutes to process one employee's payroll",
  },
  {
    property: 'banking_multiplier',
    label: 'Banking Multiplier',
    placeholder: 'Banking Multiplier *',
    description: 'Number of minutes to process one bank account transaction',
  },
  {
    property: 'bank_account_fixed_cost',
    label: 'Banking Fixed Cost',
    placeholder: 'Banking Fixed Cost *',
    description: 'Fixed cost per bank account',
  },
  {
    property: 'accounts_payable_multiplier',
    label: 'AP Multiplier',
    placeholder: 'AP Multiplier *',
    description: 'Number of minutes to process one bill',
  },
  {
    property: 'accounts_receivable_multiplier',
    label: 'AR Multiplier',
    placeholder: 'AR Multiplier *',
    description: 'Number of minutes to process one invoice',
  },
  {
    property: 'eom_multiplier',
    label: 'EOM Multiplier',
    placeholder: 'EOM Multiplier *',
    description: 'Number of minutes to process one manual journal',
  },
  {
    property: 'cost_centre_fixed_cost',
    label: 'Cost Centre Fixed Cost',
    placeholder: 'Cost Centre Fixed Cost *',
    description: 'Fixed cost per currency used',
    icon: <DollarIcon />,
  },
  {
    property: 'currency_fixed_cost',
    label: 'Currency Fixed Cost',
    placeholder: 'Currency Fixed Cost *',
    description: 'Fixed cost per currency used',
    icon: <DollarIcon />,
  },
  {
    property: 'bas_multiplier',
    label: 'BAS Multiplier',
    placeholder: 'BAS Multiplier *',
    description: 'Number of minutes to process one BAS',
  },
  {
    property: 'ias_multiplier',
    label: 'IAS Multiplier',
    placeholder: 'IAS Multiplier *',
    description: 'Number of minutes to process one IAS',
  },
  {
    property: 'bas_lodgement_fixed_cost',
    label: 'BAS Fixed Cost',
    placeholder: 'BAS Fixed Cost *',
    description: 'Fixed cost per BAS lodgment',
  },
  {
    property: 'growth_rate',
    label: 'Growth Rate',
    placeholder: 'Growth Rate *',
    description: '% to add on to total cost to account for business growth',
  },
  {
    property: 'super_rate',
    label: 'Super Rate',
    placeholder: 'Super Rate *',
    description: 'Super Rate',
    icon: <PercentageIcon />,
  },
  {
    property: 'payroll_tax_rate',
    label: 'Payroll Tax Rate',
    placeholder: 'Payroll Tax Rate *',
    description: 'Payroll Tax Rate',
    icon: <PercentageIcon />,
  },
  {
    property: 'workers_compensation_rate',
    label: 'Workers Compensation Rate',
    placeholder: 'Workers Compensation Rate *',
    description: 'Workers Compensation Rate',
    icon: <PercentageIcon />,
  },
  {
    property: 'annual_leave_rate',
    label: 'Annual Leave Rate',
    placeholder: 'Annual Leave Rate *',
    description: 'Annual Leave Rate',
    icon: <PercentageIcon />,
  },
  {
    property: 'personal_leave_rate',
    label: 'Personal Leave Rate',
    placeholder: 'Personal Leave Rate *',
    description: 'Personal Leave Rate',
    icon: <PercentageIcon />,
  },
  {
    property: 'public_holiday_rate',
    label: 'Public Holiday Rate',
    placeholder: 'Public Holiday Rate *',
    description: 'Public Holiday Rate',
    icon: <PercentageIcon />,
  },
  {
    property: 'bookkeeper_rate',
    label: 'Bookkeeper Rate',
    placeholder: 'Bookkeeper Rate *',
    description: 'Bookkeeper Base Rate',
    icon: <PercentageIcon />,
  },
  {
    property: 'senior_bookkeeper_rate',
    label: 'Senior Bookkeeper Rate',
    placeholder: 'Senior Bookkeeper Rate *',
    description: 'Senior Base Bookkeeper Rate',
    icon: <PercentageIcon />,
  },
  {
    property: 'payroll_officer_rate',
    label: 'Payroll Officer Rate',
    placeholder: 'Payroll Officer Rate *',
    description: 'Payroll Base Officer Rate',
    icon: <DollarIcon />,
  },
]

/**
 * The cost calculations page
 * @returns the cost calculations page
 */
const CostCalculations: React.FC = () => {
  const navigate = useNavigate()
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [masterData, setMasterData] = useState<MasterDataEntry[]>([])

  // Create the loading service
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  // Create the validation schema
  // Each field is required and must be a number
  const validationSchema = useMemo(() => {
    const options: {
      [key: string]: RequiredStringSchema<string | undefined, AnyObject>
    } = {}
    for (const entry of fieldDetails) {
      options[entry.property] = Yup.string()
        .required(`${entry.label} is required.`)
        .test('isNumber', `${entry.label} must be a number.`, (value) => {
          return !isNaN(Number(value))
        })
    }
    return Yup.object().shape(options)
  }, [])

  // If the user does not have access to this page,
  // redirect them to the home page
  useEffect(() => {
    getUserRole()
      .then((role) => {
        if (role !== UserRole.GlobalAdmin) {
          navigate('/')
        }
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [navigate])

  // Load the master data
  useEffect(() => {
    loadingService
      .await(getMasterData())
      .then(setMasterData)
      .catch((error: any) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [loadingService])

  // Convert the master data into a formik compatible object
  const initialVal: CostCalculationProps = useMemo(() => {
    const res: { [key: string]: string } = {}
    for (const entry of masterData) {
      // Only add the entry if it is in the field details
      if (!fieldDetails.find((field) => field.property === entry.category)) {
        continue
      }
      res[entry.category] = entry.value
    }
    return res as CostCalculationProps
  }, [masterData])

  /**
   * Handle the form submission, updates each master data entry that has changed
   * @param values the form values
   * @returns void
   */
  const handleSubmit = (
    values: CostCalculationProps,
    { setFieldError }: FormikHelpers<CostCalculationProps>
  ): void => {
    Object.entries(values).forEach(([key, value]) => {
      // Find the master data entry that matches the key
      const entry = masterData.find((entry) => entry.category === key)
      if (!entry || entry.value === value) {
        return
      }
      // Update the master data entry
      loadingService
        .await(
          updateMasterData({
            ...entry,
            value,
          })
        )
        .then((res) => {
          if (res) {
            // If the update was successful, update the master data state
            setMasterData((prev) => {
              const index = prev.findIndex((entry) => entry.category === key)
              if (index === -1) {
                return prev
              }
              const copy = [...prev]
              copy[index] = {
                ...copy[index],
                value,
              }
              return copy
            })
          } else {
            // If the update failed, set the field error
            setFieldError(key, 'Failed to update value.')
          }
        })
        .catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
    })
  }

  return (
    <div style={{ opacity: isLoading ? 0.5 : 1 }}>
      <TopNavigation tabValue={'0'} />
      <Formik
        initialValues={initialVal}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({
          values,
          setFieldValue,
          errors,
        }: FormikProps<CostCalculationProps>) => (
          <Form>
            <div className="d-md-flex justify-content-evenly mx-5 my-5">
              <Settings costCalculationSelected={true} />
              <div className={`mx-5 col-sm-12 col-lg-8`}>
                <div>
                  <div className={styles.contentQuestion}>
                    <div className={`${styles.widthLeft} ${styles.width600}`}>
                      <h2 className={`text-bolder text-almostBlack`}>
                        Cost Calculation Variables
                      </h2>
                      <h6 className={'mt-4 text-normal'}>
                        Lorem ipsum dolor sit amet consectetur. Eu facilisis
                        nunc justo morbi pretium tortor amet. Sit amet
                        consectetur. Eu facilisis nunc justo morbi pretium
                        tortor amet Lorem
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="ps-4">
                  <div>
                    {fieldDetails.map((field, index) => (
                      <div className={styles.contentQuestion} key={index}>
                        <div className={styles.widthLeft}>
                          <h6>{field.label}</h6>
                          <h6 className={'mt-4 text-normal'}>
                            {field.description}
                          </h6>
                        </div>
                        <div
                          className={`${styles.answerLength} gray-color-text`}
                        >
                          <CustomInputField
                            name={field.property}
                            icon={field.icon ? field.icon : null}
                            placeholder={field.placeholder}
                            defaultValue={values[field.property] ?? '0'}
                            className={styles.widthInput}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setFieldValue(field.property, e.target.value)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className={
                    'd-flex justify-content-end align-items-center mt-5 mb-5'
                  }
                >
                  <div className={'d-flex align-items-center'}>
                    <CustomButton
                      loading={isLoading}
                      disabled={isLoading || Object.keys(errors).length > 0}
                      type={'submit'}
                      text={'Update'}
                      icon={<RightLongArrowIcon size={14} />}
                      iconSide={'right'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default CostCalculations
