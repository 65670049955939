import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styles from '../../shared/components/candidateInfo/styles.module.scss'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'
import CustomButton from '../../shared/components/customButton/customButton'
import LeftArrow from '../../icons/leftArrow.icon'
import BagIcon from '../../icons/bag.icon'
import WalkingManIcon from '../../icons/walkingMan.icon'
import LocationIcon from '../../icons/location.icon'
import HireTeamDisplay from '../../shared/components/addTeam/addTeam'
import colors from '../../scss/variables.module.scss'
import TabBox, {
  TabBoxButton,
  Tabs,
} from '../../shared/components/tabBox/tabBox'

import { JobDetails } from '../../shared/interfaces/job.interface'
import { useLocation, useNavigate } from 'react-router-dom'
import { CandidateDetails } from '../../shared/interfaces/candidate.interface'
import {
  getAllCandidates,
  getCandidateDetails,
  getHiringProcess,
  getHiringProcessData,
  getHiringProcesses,
  getHiringStageOverviewForJob,
  updateJobStatus,
} from '../../shared/services/hiring.service'
import CandidateInfo from './dashboardCandidateInfo'
import CandidateList from './dashboardCandidateList'
import { HiringPage, HiringStage } from '../../shared/enums/hiring.enum'
import { HiringProcess } from '../../shared/interfaces/hiring.interface'
import LoadingService from '../../shared/services/loading.service'
import JobInner from './jobInner'
import DownArrowIcon from '../../icons/downArrow.icon'
import { getUserRole } from '../../shared/services/auth.service'
import { UserRole } from '../../shared/enums/auth.enum'
import BuildingIcon from '../../icons/building.icon'
import HiringProcessContext from '../../context/hiring.process.context'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'
import { JobStatus, WorkCommitment } from '../../shared/enums/job.enum'
import CustomDropdown from '../../shared/components/customDropdown/customDropdown'
import { HiringCandidateContext } from '../../context/hiring.candidate.context'

export type HiringStageType = {
  stage: string
  count: number
}

const DashboardCandidate = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [page, setPage] = useState<string>(HiringPage.Candidates)
  const [job, setJob] = useState<JobDetails | null>(null)
  const [drawer, setDrawer] = useState<boolean>(false)
  const [stepper, setStepper] = useState<boolean>(false)
  const [, setHiringStageOverview] = useState<HiringStageType[]>([])
  const selectedCandidate = useRef<string>('')
  const candidateDetails = useRef<any[]>([])
  const candidateHiringData = useRef<HiringProcess[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )
  const [, setPrivilege] = useState<boolean>(false)
  const [enableButtons, setEnableButtons] = useState<boolean>(false)
  const [disableTabsAndButtons, setDisableTabsAndButtons] =
    useState<boolean>(false)
  const { hiringStatus, updateHiringStatus } = useContext(HiringProcessContext)
  const { addCandidateData, addNewCandidateData } = useContext(
    HiringCandidateContext
  )
  const [cultureCatchUpCnt, setCultureCatchUpCnt] = useState<number>(0)
  const [interviewCnt, setInterviewCnt] = useState<number>(0)
  const [referenceCheckCnt, setReferenceCheckCnt] = useState<number>(0)
  const [negotiationCnt, setNegotiationCnt] = useState<number>(0)
  const [paperWorkCnt, setPaperWorkCnt] = useState<number>(0)
  const [completeCnt, setCompleteCnt] = useState<number>(0)
  const [getSelectedCandidateDetails, setGetSelectedCandidateDetails] =
    useState<any>([])
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const subTabList = [
    { text: 'Candidates', value: 'Candidates' },
    { text: 'Culture Catch-Up', value: 'Culture_Catch_Up' },
    { text: 'Interview', value: 'Interview' },
    { text: 'Reference Check', value: 'Reference_Check' },
    { text: 'Negotiation', value: 'Negotiation' },
    { text: 'Documentation', value: 'Paper_Work' },
  ]
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  // When the page loads, we need to get the job details and the list of hiring processes for that job
  useEffect(() => {
    if (location?.state?.job) {
      setJob(location.state.job)
      loadingService
        .await(getHiringProcesses(location.state.job.id))
        .then((da) => {
          candidateHiringData.current = da
        })
        .catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
      loadingService
        .await(getHiringProcessData(location?.state.job.id))
        .then((res) => {
          const params = {
            jobID: location.state.job.id,
            search: '',
            page: 0,
            sortBy: false,
            userId: res.data.result.map((md: any) => md.candidate_id),
          }
          loadingService
            .await(
              getAllCandidates(params).then((res) => {
                addCandidateData(res)
              })
            )
            .catch((error) => {
              setErrorModel(true)
              setErrorMessage(error.message)
            })
        })
        .catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
      addCandidateData('')
      setStatus(location.state.job.jobs_status)
    } else {
      navigate('/dashboard')
    }
    // eslint-disable-next-line
  }, [loadingService, location?.state?.job, navigate])
  useEffect(() => {
    window.scrollTo(0, 0) // Scrolls to the top of the page
  }, [])

  const handleStageChange = useCallback(async () => {
    setIsLoading(true)
    if (!job) return
    try {
      const res = await getHiringStageOverviewForJob(job.id)
      if (res) {
        setCultureCatchUpCnt(
          res.find((s) => s.stage === HiringStage.CultureCatchUp)?.count ?? 0
        )
        setInterviewCnt(
          res.find((s) => s.stage === HiringStage.Interview)?.count ?? 0
        )
        setReferenceCheckCnt(
          res.find((s) => s.stage === HiringStage.ReferenceCheck)?.count ?? 0
        )
        setNegotiationCnt(
          res.find((s) => s.stage === HiringStage.Negotiation)?.count ?? 0
        )
        setPaperWorkCnt(
          res.find(
            (s) =>
              s.stage === HiringStage.PaperWork ||
              s.stage === HiringStage.Completed
          )?.count ?? 0
        )
        setCompleteCnt(
          res.find((s) => s.stage === HiringStage.Completed)?.count ?? 0
        )
        setHiringStageOverview(res)
        setIsLoading(false)
      }
      if (completeCnt != 0) {
        updateHiringStatus('Completed')
      } else if (paperWorkCnt != 0) {
        updateHiringStatus('Paper Work')
      } else if (negotiationCnt != 0) {
        updateHiringStatus('Negotiation')
      } else if (referenceCheckCnt != 0) {
        updateHiringStatus('Reference Check')
      } else if (interviewCnt != 0) {
        updateHiringStatus('Interview')
      } else if (cultureCatchUpCnt != 0) {
        updateHiringStatus('Culture Catch Up')
      } else {
        updateHiringStatus('Screening')
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }, [
    job,
    completeCnt,
    paperWorkCnt,
    negotiationCnt,
    referenceCheckCnt,
    interviewCnt,
    cultureCatchUpCnt,
    updateHiringStatus,
  ])

  useEffect(() => {
    handleStageChange()
  }, [handleStageChange, page])

  useEffect(() => {
    getUserRole()
      .then((role) => {
        if (role === UserRole.Manager || role === UserRole.GlobalAdmin) {
          setPrivilege(true)
          setEnableButtons(true)
        } else {
          setDisableTabsAndButtons(true)
          if (job?.status === 'no-cost-generated') {
            setPrivilege(false)
            return
          } else {
            setPrivilege(true)
          }

          if (job?.hiring_team_completed) {
            setPrivilege(true)
          } else {
            setPrivilege(false)
            return
          }
        }
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [job])

  /**
   * Used to check if the user is a global admin, and if so, enable status update
   */
  useEffect(() => {
    getUserRole()
      .then((role) => {
        if (role === UserRole.GlobalAdmin) {
          setIsAdmin(true)
        }
      })
      .catch(() => {
        setError(true)
        setErrorMsg('Error getting user role')
      })
  }, [job])

  // When a candidate is selected, we need to get their details and hiring data
  // First check if we already have the data in state, if not, get it from the server
  const handleSelectCandidate = (candidate: any, page: any) => {
    if (candidate) {
      setPage(
        page === HiringStage.Screening
          ? HiringStage.Candidates
          : page === HiringStage.Pending
            ? HiringStage.Candidates
            : page
      )
      selectedCandidate.current = candidate
      if (!candidateDetails.current.find((c) => c.id === candidate)) {
        loadingService
          .await(getCandidateDetails(candidate))
          .then((details: CandidateDetails | null) => {
            if (details === null) return
            candidateDetails.current = [
              ...candidateDetails.current.filter((c) => c.id !== candidate),
              details,
            ]
            setGetSelectedCandidateDetails([
              ...candidateDetails.current.filter((c) => c.id == details.id),
              details,
            ])
            selectedCandidate.current = details?.id
          })
          .catch((error) => {
            setErrorModel(true)
            setErrorMessage(error.message)
          })
      } else {
        selectedCandidate.current = candidate
        setGetSelectedCandidateDetails([
          ...candidateDetails.current.filter((c) => c.id == candidate),
        ])
      }
      if (
        !candidateHiringData.current.find((c) => c.candidate_id === candidate)
      ) {
        loadingService
          .await(
            getHiringProcess({
              job_id: job?.id ?? '',
              candidate_id: candidate,
            })
          )
          .then((hiringData: HiringProcess | null) => {
            if (hiringData === null) return
            candidateHiringData.current = [
              ...candidateHiringData.current.filter((c) => c.id !== candidate),
              hiringData,
            ]
          })
          .catch((error) => {
            setErrorModel(true)
            setErrorMessage(error.message)
          })
      } else {
        selectedCandidate.current = candidate
      }
    } else {
      selectedCandidate.current = ''
    }
  }

  // If the candidate info component tries to update the candidate details or hiring data, we need to update the state
  const handleCandidateUpdate = (details: CandidateDetails) => {
    candidateDetails.current = [
      ...candidateDetails.current.filter((c) => c.id !== details.id),
      details,
    ]
  }
  const handleCandidateHiringDataUpdate = (hiringData: HiringProcess) => {
    candidateHiringData.current = [
      ...candidateHiringData.current.filter((c) => c.id !== hiringData.id),
      hiringData,
    ]
  }

  const handleTabChange = (tab: string) => {
    setPage(tab)
  }

  /**
   * Updates the job status and navigates to the dashboard page.
   * @returns {Promise<void>}
   */
  const handleUpdateJobStatus = async () => {
    setIsLoading(true)
    if (status !== '' && location.state.job?.id !== null) {
      if (job?.jobs_status === status) {
        setError(true)
        setIsLoading(false)
        setErrorMsg(`Status is already ${status}`)
        return
      } else {
        const params = {
          job_id: job!.id,
          job_status: status,
        }
        loadingService
          .await(updateJobStatus(params))
          .then((res) => {
            if (res.status) {
              setIsLoading(false)
              navigate('/dashboard')
            } else {
              setIsLoading(false)
              setError(true)
              setErrorMsg(res.message)
            }
          })
          .catch((error) => {
            setError(true)
            setErrorMsg(error.message)
          })
      }
    }
  }

  return (
    <>
      <div className={'w-100'}>
        <TopNavigation tabValue={'0'} />
        <div className={styles.infoContent}>
          <CustomButton
            onClick={() => navigate('/dashboard')}
            icon={<LeftArrow />}
            iconSide={'left'}
            text={'Go Back'}
            className={styles.transparentBtn}
          />
          <div
            className={`mt-5 d-flex w-100 flex-column justify-content-center align-items-between`}
          >
            <div className={'d-flex justify-content-start align-items-center'}>
              <div className={'d-flex align-items-center'}>
                <div className="d-flex flex-row justify-content-between">
                  <h3 className={'text-bolder'}>{job?.title}</h3>
                  {job && job.jobs_status === JobStatus.Inactive && (
                    <h6 className={styles.inactiveTag}>
                      {job.jobs_status.charAt(0).toUpperCase() +
                        job.jobs_status.slice(1)}
                    </h6>
                  )}
                </div>
                {job &&
                  job?.skills
                    ?.filter((fd: any) => fd.type === 'task_expertise')
                    .map((item, index) => {
                      return (
                        <div className={styles.payableTag} key={`txp${index}`}>
                          <h6>{item.value}</h6>
                        </div>
                      )
                    })}
              </div>
            </div>
            <div
              className={`justify-content-between align-items-center mt-3 ${styles.responsive}`}
            >
              <div className={'d-md-flex align-items-center'}>
                <div className={'d-flex me-4 mt-2 mt-md-0'}>
                  <BagIcon />
                  <h6 className={'ms-3 gray-color-text'}>
                    {job?.commitment === WorkCommitment.PermanentFullTime
                      ? 'Full Time'
                      : job?.commitment === WorkCommitment.PermanentPartTime
                        ? 'Part Time'
                        : job?.commitment === WorkCommitment.Casual
                          ? 'Casual'
                          : 'Unknown Commitment'}
                  </h6>
                </div>
                <div className={`mt-2 mt-md-0 ${styles.borderClass}`}>
                  <WalkingManIcon />
                  <h6 className={'ms-3 gray-color-text'}>{job?.work_type}</h6>
                </div>
                <div className={`mt-2 mt-md-0 ${styles.borderClass}`}>
                  <LocationIcon />
                  <h6 className={'ms-3 gray-color-text'}>{job?.location}</h6>
                </div>
                <div className={'d-flex ms-md-4 mt-2 mt-md-0'}>
                  <BuildingIcon />
                  <h6 className={'ms-3 gray-color-text'}>{job?.org_name}</h6>
                </div>
              </div>
              <div
                className={
                  'd-md-flex justify-content-between justify-content-md-start mt-2 mt-md-0'
                }
              >
                <HireTeamDisplay
                  job_id={location.state.job.id ?? ''}
                  disabled={location.state.disabled || !enableButtons}
                />
                <div className={styles.statusBorder}>
                  <h6 className={'gray-color-text mb-4'}>Hiring Status</h6>
                  <div className={`${styles.status} mt-4`}>
                    <h6 className={'text-success'}>{hiringStatus}</h6>
                  </div>
                </div>
                {isAdmin && (
                  <div className={styles.statusBorder}>
                    <div className={styles.statusUpdate}>
                      <h6 className={'gray-color-text mb-4 mt-3 mt-md-0'}>
                        Update Status
                      </h6>
                      <div className={styles.updateDropdown}>
                        <CustomDropdown
                          dataList={[
                            { text: 'Active', value: 'active' },
                            { text: 'Inactive', value: 'inactive' },
                          ]}
                          selectedValue={{
                            text: status
                              ? status.charAt(0).toUpperCase() + status.slice(1)
                              : '',
                            value: status ?? '',
                          }}
                          getSelectedItem={(item: any) => setStatus(item.value)}
                        />
                      </div>
                      <CustomButton
                        className={`mt-2 ${styles.borderBtn}`}
                        disabled={isLoading || location.state.disabled}
                        loading={isLoading}
                        onClick={handleUpdateJobStatus}
                        text={'Update'}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <CustomButton
              text={'View Role Details'}
              className={`mt-3 mt-md-0 ${styles.outlinedBtn}`}
              onClick={() => {
                setDrawer(true)
              }}
            />
          </div>
          <TabBox
            selectedTabName={page}
            className={`mt-5 ${styles.tabWidth} ${styles.displayResponsive}`}
            onTabChange={handleTabChange}
            layoutId={'subTab'}
          >
            <Tabs>
              <TabBoxButton tabName={HiringPage.Candidates}>
                <h6>Candidates</h6>
              </TabBoxButton>
              <TabBoxButton
                tabName={HiringPage.CultureCatchUp}
                notificationCount={cultureCatchUpCnt}
                border
              >
                <h6>Culture Catch-Up</h6>
              </TabBoxButton>
              <TabBoxButton
                tabName={HiringPage.Interview}
                notificationCount={interviewCnt}
                border
              >
                <h6>Interview</h6>
              </TabBoxButton>
              <TabBoxButton
                tabName={HiringPage.ReferenceCheck}
                notificationCount={referenceCheckCnt}
                border
              >
                <h6>Reference Check</h6>
              </TabBoxButton>
              <TabBoxButton
                disabled={disableTabsAndButtons}
                tabName={HiringPage.Negotiation}
                notificationCount={negotiationCnt}
                border
              >
                <h6>Negotiation</h6>
              </TabBoxButton>
              <TabBoxButton
                disabled={disableTabsAndButtons}
                tabName={HiringPage.PaperWork}
                notificationCount={paperWorkCnt}
                border
              >
                <h6>Documentation</h6>
              </TabBoxButton>
            </Tabs>
          </TabBox>

          <div
            className={`mt-3 ${styles.mobileStepper}`}
            onClick={() => setStepper(!stepper)}
          >
            <div
              className={'d-flex justify-content-between align-items-center'}
            >
              <h6>{page}</h6>
              <DownArrowIcon color={colors.blackColor} />
            </div>
            {stepper && (
              <div className={styles.subMobileStepper}>
                {subTabList.map((value, index) => (
                  <div
                    key={index}
                    className={styles.stepperInside}
                    onClick={() => handleTabChange(value.value)}
                  >
                    <h6>{value.text}</h6>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={`mt-5 ${styles.responsive}`}>
            <div className={styles.leftContent}>
              <CandidateList
                addToList={(candidate: any) => addNewCandidateData(candidate)}
                jobStatus={job?.status ?? ''}
                disabled={isLoading || location.state.disabled}
                page={page}
                jobID={job?.id ?? ''}
                onCandidateSelect={handleSelectCandidate}
                hiringData={candidateHiringData.current}
                selectedCandidateId={selectedCandidate.current ?? ''}
                setPage={setPage}
              />
            </div>
            <div className={styles.rightSide}>
              <CandidateInfo
                disableTabsAndButtons={disableTabsAndButtons}
                disabled={isLoading || location.state.disabled}
                tab={page}
                candidate={
                  getSelectedCandidateDetails.length > 0
                    ? getSelectedCandidateDetails[0]
                    : null
                }
                hiringData={
                  candidateHiringData.current.find(
                    (c) => c.candidate_id === selectedCandidate.current
                  ) ?? null
                }
                onUpdateCandidate={handleCandidateUpdate}
                onUpdateHiringData={handleCandidateHiringDataUpdate}
                setParentPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
      {error && (
        <ErrorBannerModal
          open={error}
          errorMessage={errorMsg}
          onClose={() => setError(false)}
        />
      )}
      {drawer && job && (
        <JobInner onClose={(val) => setDrawer(val)} details={job} />
      )}
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default DashboardCandidate
